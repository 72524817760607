import React, { useState } from "react";
import { Alert, Button, Glyphicon, Table } from "react-bootstrap";

import SortableTableHeader from "./SortableTableHeader";
import EditProject from "./modals/EditProject";
import { toIcelandicDate } from "../utils/DateFormat";
import { numberToFixedString } from "../utils/Numbers";
import { capitalizeFirstLetter } from "../utils/HelperFunctions";
import { translate } from "../utils/Translations";
import ProjectComments from "./modals/ProjectComments";

// prettier-ignore
const translations = {
  "No projects have been created": { is: "Engin verkefni hafa verið búin til" },
  "Name": { is: "Nafn" },
  "Total hours": { is: "Samtals tímar" },
  "Created": { is: "Búið til" },
  "Last entry": { is: "Síðasta færsla" },
  "Edit": { is: "Breyta" },
  "Open/Close": { is: "Opna/Loka" },
  "Close": { is: "Loka" },
  "Open": { is: "Opna" },
  "Open projects": { is: "Opin verkefni" },
  "Closed projects": { is: "Lokuð verkefni" },
  "Created by": { is: "Búið til af" },
  "Desc.": { is: "Lýsing" },
  "Company": { is: "Fyrirtæki" },
  "Unknown": { is: "Óþekkt" },
};

const t = key => translate(key, translations);

function ProjectsTable({
  projects,
  employeeIdToName,
  sortedBy,
  onChangeProjectState,
  onEditProject,
  onProjectComments,
  onSortProjects,
}) {
  const getCreatedByText = idOrString => {
    if (idOrString === "Company") return t("Company");
    const maybeEmployee = employeeIdToName[idOrString];
    if (maybeEmployee) return capitalizeFirstLetter(maybeEmployee);
    return t("Unknown");
  };
  const zeroProjects = projects.length === 0;
  const someProjectsAreEmployeeCreated = projects.some(
    project => project.created_by !== "Company"
  );
  const EmptyProjectTableNoticeRow = (
    <tr>
      <td colSpan={9} style={{ textAlign: "center" }}>
        <Alert bsStyle="warning" style={{ marginBottom: 0 }}>
          {t("No projects have been created")}
        </Alert>
      </td>
    </tr>
  );

  return (
    <Table responsive striped bordered condensed hover>
      <thead>
        <tr>
          <SortableTableHeader
            name={t("Name")}
            attribute="name"
            sortedBy={sortedBy}
            onSort={onSortProjects}
          />
          <SortableTableHeader
            name={t("Total hours")}
            attribute="total_working_hours"
            sortedBy={sortedBy}
            onSort={onSortProjects}
          />
          <SortableTableHeader
            name={t("Created")}
            attribute="created"
            sortedBy={sortedBy}
            onSort={onSortProjects}
          />
          <SortableTableHeader
            name={t("Last entry")}
            attribute="latest_work_log"
            sortedBy={sortedBy}
            onSort={onSortProjects}
          />
          <th className="text-center">{t("Desc.")}</th>
          <th className="text-center">{t("Edit")}</th>
          <th className="text-center">{t("Open/Close")}</th>
          {someProjectsAreEmployeeCreated && (
            <SortableTableHeader
              name={t("Created by")}
              attribute="created_by"
              sortedBy={sortedBy}
              onSort={onSortProjects}
            />
          )}
        </tr>
      </thead>
      <tbody>
        {/* Display a notice if the projects table is empty */}
        {zeroProjects ? EmptyProjectTableNoticeRow : null}

        {/* Projects, one per row */}
        {projects.map((item, i) => {
          return (
            <tr key={i}>
              <td>{item.name}</td>
              <td>
                {item.total_working_hours
                  ? numberToFixedString(item.total_working_hours)
                  : null}
              </td>
              <td>{toIcelandicDate(item.created)}</td>
              <td>{toIcelandicDate(item.latest_work_log)}</td>
              <td className="text-center">
                {item.description && (
                  <Button onClick={() => onProjectComments(item)}>
                    <Glyphicon glyph="tasks" />
                  </Button>
                )}
              </td>
              <td className="text-center">
                <Button
                  bsSize="xsmall"
                  bsStyle="default"
                  onClick={() => onEditProject(item)}
                >
                  <Glyphicon glyph="edit" />
                </Button>
              </td>
              <td className="text-center">
                <Button
                  bsSize="xsmall"
                  onClick={() => onChangeProjectState(item)}
                >
                  {item.is_open ? t("Close") : t("Open")}
                </Button>
              </td>
              {someProjectsAreEmployeeCreated && (
                <td className="cell-center" title={item.description}>
                  {getCreatedByText(item.created_by)}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function Projects({
  projects,
  employees,
  sortedBy,
  onChangeProjectState,
  onEditProject,
  onSortProjects,
}) {
  const [showEditProject, setShowEditProject] = useState(false);
  const [showProjectComments, setProjectComments] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const employeeIdToNameMap = Object.fromEntries(
    employees.map(({ id, name }) => [id, name])
  );

  const openEditProjectModal = project => {
    setShowEditProject(true);
    setSelectedProject(project);
  };
  const closeEditProjectModal = () => {
    setShowEditProject(false);
    setSelectedProject(null);
  };
  const openProjectCommentsModal = project => {
    setProjectComments(true);
    setSelectedProject(project);
  };
  const closeProjectCommentsModal = () => {
    setProjectComments(false);
    setSelectedProject(null);
  };

  const closedProjects = projects.filter(project => !project.is_open);
  const hasClosedProjects = closedProjects.length !== 0;
  const projectID = (selectedProject && selectedProject.id) || 0;

  return (
    <div>
      {hasClosedProjects ? <h3>{t("Open projects")}</h3> : null}
      <ProjectsTable
        projects={projects.filter(project => project.is_open)}
        employeeIdToName={employeeIdToNameMap}
        sortedBy={sortedBy}
        onEditProject={openEditProjectModal}
        onProjectComments={openProjectCommentsModal}
        onChangeProjectState={onChangeProjectState}
        onSortProjects={onSortProjects}
      />

      {hasClosedProjects ? (
        <div style={{ marginTop: "3em" }}>
          <h3>{t("Closed projects")}</h3>
          <ProjectsTable
            projects={closedProjects}
            employeeIdToName={employeeIdToNameMap}
            sortedBy={sortedBy}
            onEditProject={openEditProjectModal}
            onProjectComments={openProjectCommentsModal}
            onChangeProjectState={onChangeProjectState}
            onSortProjects={onSortProjects}
          />
        </div>
      ) : null}

      <EditProject
        show={showEditProject}
        onSubmit={onEditProject}
        onCloseModal={closeEditProjectModal}
        project={selectedProject}
        key={projectID}
      />

      <ProjectComments
        show={showProjectComments}
        project={selectedProject}
        onCloseModal={closeProjectCommentsModal}
      />
    </div>
  );
}

export default Projects;
