import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Alert, Button, Glyphicon, Table } from "react-bootstrap";

import { sortTransactions } from "../actions";

import WorkLogComments from "./modals/WorkLogComments";
import ChangeWorkLog from "./modals/ChangeWorkLog";
import MissingLocationModal from "./modals/MissingLocationModal";
import SortableTableHeader from "./SortableTableHeader";

import { toIcelandicDate, toHoursAndMinutes } from "../utils/DateFormat";
import { numberToFixedString } from "../utils/Numbers";

import "./Transactions.css";
import { translate } from "../utils/Translations";
import {
  commentListIsEmpty,
  isImageComment,
  isTextComment,
} from "../utils/HelperFunctions";

// prettier-ignore
const translations = {
  "Add": { is: "Bæta við" },
  "None": { is: "Vantar" },
  "no_comments_deactivated_employee": {
    en: "None",
    is: "Engar",
  },
  "No time entries for the selected time period": {
    is: "Engar vinnu færslur fyrir valið tímabil"
  },
  "Total hours": { is: "Samtals tímar" },
  "Edit": { is: "Breyta" },
  "Created by": { is: "Búin til af" },
  "hours": { is: "klst" },
  "Project": { is: "Verk" },
  "In": { is: "Inn" },
  "Out": { is: "Út" },
  "Hours": { is: "Vinnustundir" },
  "Comments": { is: "Athugasemdir" },
  "Location": { is: "Staðsetning" },
  "Date": { is: "Dagsetning" },
  "Employee": { is: "Starfsmaður" },
  "Company": { is: "Fyrirtæki" },
  "CompanyTimeClock": { en: "Time clock", is: "Stimpilklukku" },
  "CreatedByEmployee": { en: "Employee", is: "Starfsmanni" },
};

const t = key => translate(key, translations);

/** True if totalTime is a number bigger or equal to 0. False otherwise. */
const isTotalTimeSet = totalTime => totalTime !== null && totalTime >= 0;

/** Ensures all icon buttons have the same min width. Very uneven otherwise. */
const minimumWidth = {
  minWidth: "6em",
};

/**
 * Returns a green button with icons if the given time entry contains comments.
 * If the comments include an image a camera icon is displayed. If there are
 * only text comments a chat bubble icon is shown. If the comments include both
 * at least one image and one text comment both icons are shown. If there are
 * no comments the button is white with the text "Add".
 *
 * This helps users see at a glance in the table if there are comments or not.
 * Otherwise, users would need to press the button to check every time.
 *
 * @param worklog {object}
 * @param onClick {function}
 * @return {*} React Bootstrap button
 */
const CommentButton = ({ worklog, onClick }) => {
  const isDeactivatedEmployee = useSelector(
    state => state.user?.data?.disabled ?? false
  );

  const comments = worklog?.work_log_comments ?? [];
  const isEmpty = commentListIsEmpty(comments);
  const containsImage = comments.some(({ comment }) => isImageComment(comment));
  const containsText = comments.some(({ comment }) => isTextComment(comment));
  const buttonStyle = isEmpty ? "default" : "success";

  let label = null;
  if (isEmpty) label = t("Add");
  if (isEmpty && isDeactivatedEmployee) {
    // deactivated employees can not add comments
    label = t("no_comments_deactivated_employee");
  }

  return (
    <Button
      bsStyle={buttonStyle}
      bsSize="xsmall"
      onClick={onClick}
      style={minimumWidth}
    >
      {label}
      {containsImage ? <Glyphicon glyph="camera" /> : null}
      {containsImage && containsText ? " " : null}
      {containsText ? <Glyphicon glyph="comment" /> : null}
    </Button>
  );
};

/**
 * Returns a green button with a location icon if a time entry contains a
 * geolocation. Otherwise, we return a white "None" button that pops up a modal
 * explaining what could cause the absence.
 *
 * @param worklog {object}
 * @param onShowMap {function} a function that takes a worklog object as input.
 *   Opens up the map modal and shows the locations for that worklog.
 * @param onShowMissing {function} opens up the modal that explains why the
 *   location might be missing.
 */
const LocationButton = ({ worklog, onShowMap, onShowMissing }) => {
  const hasLocation = worklog?.has_location ?? false;
  const style = hasLocation ? "success" : "default";
  const onClick = hasLocation ? () => onShowMap(worklog) : onShowMissing;
  const content = hasLocation ? <Glyphicon glyph="map-marker" /> : t("None");

  return (
    <Button
      bsStyle={style}
      bsSize="xsmall"
      onClick={onClick}
      style={minimumWidth}
    >
      {content}
    </Button>
  );
};

/**
 * Displays a green button with a flag if the time entry has previously been
 * modified. Otherwise, as white button with an edit icon is shown.
 *
 * @param workLog {object}
 * @param onClick {function}
 */
const EditButton = ({ workLog, onClick }) => {
  if (!workLog) return;

  const hasBeenEdited =
    workLog.original_start_time || workLog.original_end_time;
  const color = hasBeenEdited ? "success" : "default";
  const glyph = hasBeenEdited ? "flag" : "edit";

  return (
    <Button bsStyle={color} bsSize="xsmall" onClick={onClick}>
      <Glyphicon glyph={glyph} />
    </Button>
  );
};

const Transactions = props => {
  const {
    transactions,
    onShowLocationClick,
    onCreateWorkLogComment,
    onChangeWorkLog,
    onDeleteWorkLog,
    projects,
    hideEmployeeColumn,
    hideEditColumn,
    dispatch,
  } = props;
  const [showComments, setShowComment] = useState(false);
  const [showChangeWorkLog, setshowChangeWorkLog] = useState(false);
  const [selectedWorkLog, setSelectedWorkLog] = useState(null);
  const [showMissingLocationModal, setShowMissingLocationModal] =
    useState(false);

  const displayComments = workLog => {
    setShowComment(true);
    setSelectedWorkLog(workLog);
  };
  const closeModal = () => {
    setShowComment(false);
    setSelectedWorkLog(null);
  };

  const changeWorkLog = workLog => {
    setshowChangeWorkLog(true);
    setSelectedWorkLog(workLog);
  };
  const closeChangeWorkLog = () => {
    setshowChangeWorkLog(false);
    setSelectedWorkLog(null);
  };

  const openMissingLocationModal = () => setShowMissingLocationModal(true);
  const closeMissingLocationModal = () => setShowMissingLocationModal(false);

  const getWorkLogColor = workLog => {
    if (!workLog) return {};
    if (!isTotalTimeSet(workLog.total_time))
      return { backgroundColor: "bisque" };
  };

  const getCreatedBy = createdBy => {
    if (createdBy === "Employee") {
      // We already store "Employee" translation in another declension
      // (fallbeyging). Starfsmaður vs Starfsmanni, we have another key.
      return t("CreatedByEmployee");
    }
    return t(createdBy);
  };

  /**
   * Converts the totalTime to hours worked if it's set. Example: 3600 seconds
   * would be "1.00". If totalTime isn't set we return null which will display
   * nothing.
   */
  const getTotalHoursWorked = totalTime => {
    return isTotalTimeSet(totalTime) ? numberToFixedString(totalTime) : null;
  };

  const zeroWorklogs = transactions.data.length === 0;
  const EmptyWorklogTableNoticeRow = (
    <tr>
      <td colSpan={10} style={{ textAlign: "center" }}>
        <Alert bsStyle="warning" style={{ marginBottom: 0 }}>
          {t("No time entries for the selected time period")}
        </Alert>
      </td>
    </tr>
  );

  const totalHoursWorked = transactions.data
    .map(t => t.total_time || 0)
    .reduce((a, b) => a + b, 0);

  // Color the total time numbers if they exceed some hours as the company
  // might want to take a better look at them.
  const getTotalTimeStyle = totalTime => {
    // Most likely someone forgot to log out, mark red
    if (totalTime >= 3600 * 16) return { color: "red", fontWeight: "bold" };

    // Fine, but it is nice to see if someone is working more than 10 hours.
    if (totalTime >= 3600 * 10) return { fontWeight: "bold" };
  };

  const totalHoursWorkedLabel = `${numberToFixedString(totalHoursWorked)} ${t(
    "hours"
  )}`;
  const leftColspan = hideEmployeeColumn ? 4 : 5;
  const rightColspan = hideEditColumn ? 3 : 4;
  const TotalHoursRow = (
    <tr className="tableTotalRow">
      <td colSpan={leftColspan} style={{ textAlign: "right" }}>
        <strong style={{ marginRight: "0.5em" }}>{t("Total hours")}</strong>
      </td>
      <td className="text-center">
        <strong>{totalHoursWorkedLabel}</strong>
      </td>
      <td colSpan={rightColspan} />
    </tr>
  );

  return (
    <div style={{ marginTop: "5px" }}>
      <Table responsive striped bordered condensed hover>
        <thead>
          <tr>
            {hideEmployeeColumn ? null : (
              <SortableTableHeader
                name={t("Employee")}
                attribute="employee.name"
                sortedBy={transactions.sortedBy}
                onSort={attribute => dispatch(sortTransactions(attribute))}
              />
            )}
            <SortableTableHeader
              name={t("Project")}
              attribute="project.name"
              sortedBy={transactions.sortedBy}
              onSort={attribute => dispatch(sortTransactions(attribute))}
            />
            <SortableTableHeader
              name={t("Date")}
              attribute="start_time"
              sortedBy={transactions.sortedBy}
              onSort={attribute => dispatch(sortTransactions(attribute))}
            />
            <th className="text-center">{t("In")}</th>
            <th className="text-center">{t("Out")}</th>
            <SortableTableHeader
              name={t("Hours")}
              attribute="total_time"
              sortedBy={transactions.sortedBy}
              onSort={attribute => dispatch(sortTransactions(attribute))}
            />
            <th className="text-center hidden-print">{t("Comments")}</th>
            <th className="text-center hidden-print">{t("Location")}</th>
            {hideEditColumn ? null : (
              <th className="text-center hidden-print">{t("Edit")}</th>
            )}
            <th className="text-center">{t("Created by")}</th>
          </tr>
        </thead>
        <tbody>
          {/* Display a notice if the work log table is empty */}
          {zeroWorklogs ? EmptyWorklogTableNoticeRow : null}

          {/* Work log entries, one per row */}
          {transactions.data.map((item, i) => {
            return (
              <tr key={i} style={getWorkLogColor(item)}>
                {hideEmployeeColumn ? null : (
                  <td className="verticalMiddle">{item.employee.name}</td>
                )}
                <td className="verticalMiddle">{item.project.name}</td>
                <td className="cell-center">
                  {toIcelandicDate(item.start_time)}
                </td>
                <td className="cell-center">
                  {toHoursAndMinutes(item.start_time)}
                </td>
                <td className="cell-center">
                  {toHoursAndMinutes(item.end_time) || null}
                </td>
                <td
                  className="cell-center"
                  style={getTotalTimeStyle(item.total_time)}
                >
                  {getTotalHoursWorked(item.total_time)}
                </td>
                <td className="cell-center hidden-print">
                  <CommentButton
                    worklog={item}
                    onClick={() => displayComments(item)}
                  />
                </td>
                <td className="cell-center hidden-print">
                  <LocationButton
                    worklog={item}
                    onShowMap={onShowLocationClick}
                    onShowMissing={() => openMissingLocationModal()}
                  />
                </td>
                {hideEditColumn ? null : (
                  <td className="cell-center hidden-print">
                    <EditButton
                      workLog={item}
                      onClick={() => changeWorkLog(item)}
                    />
                  </td>
                )}
                <td className="cell-center">{getCreatedBy(item.created_by)}</td>
              </tr>
            );
          })}

          {/*
            Display a total/sum row at the bottom
            if there are some work logs in it
          */}
          {zeroWorklogs ? null : TotalHoursRow}
        </tbody>
      </Table>

      <WorkLogComments
        show={showComments}
        id={selectedWorkLog ? selectedWorkLog.id : ""}
        onSubmit={onCreateWorkLogComment}
        onCloseModal={closeModal}
        workLogComments={
          selectedWorkLog ? selectedWorkLog.work_log_comments : []
        }
      />

      <MissingLocationModal
        show={showMissingLocationModal}
        onCloseModal={closeMissingLocationModal}
      />

      <ChangeWorkLog
        show={showChangeWorkLog}
        workLog={selectedWorkLog}
        projects={projects}
        onSubmit={onChangeWorkLog}
        onDelete={onDeleteWorkLog}
        onCloseModal={closeChangeWorkLog}
      />
    </div>
  );
};

export default connect(store => {
  return {
    transactions: store.transactions,
  };
})(Transactions);
