import React from "react";
import { Row, Col } from "react-bootstrap";

import { translateWithGlobals } from "../../utils/Translations";
import { isIcelandicDomain } from "../../utils/HelperFunctions";
import { trialPeriodInDays } from "../../utils/Constants";

// prettier-ignore
const translations = {
  "per month": { "is": "þkr á mánuði" },
  "1 - 4 employees": { "is": "1 - 4 starfsmenn" },
  "5 - 24 employees": { "is": "5 - 24 starfsmenn" },
  "25 - 49 employees": { "is": "25 - 49 starfsmenn" },
  "50 - 200 employees": { "is": "50 - 200 starfsmenn" },
};

const t = key => translateWithGlobals(key, translations);

// 2023 pricing tiers in each currency for 1-4, 5-24, 25-49, 50-200 employees
const prices = {
  ISK: [8, 14, 30, 60],
  EUR: [50, 100, 200, 400],
  USD: [50, 100, 200, 400],
  GBP: [50, 100, 200, 400],
};

/** Renders a currency text label like €50 */
const Price = ({ price, currency }) => {
  const getCurrencySymbol = currency => {
    if (currency === "EUR") return "€";
    if (currency === "GBP") return "£";
    if (currency === "USD") return "$";

    // Fallback or default
    return "€";
  };

  // For Icelandic krónur we don't use superscript for the currency symbols
  if (currency === "ISK") {
    return <span className="number">{price}</span>;
  }

  return (
    <span className="number">
      <sup>{getCurrencySymbol(currency)}</sup>
      {price}
    </span>
  );
};

const IcelandicPricingDescription = (
  <>
    <p className="text-center" style={{ lineHeight: 2 }}>
      Engin falin gjöld <br />
      Frí {trialPeriodInDays} daga prufa <br />
      Segðu upp hvenær sem er <br />
    </p>

    <p className="text-center" style={{ marginTop: "2.5em" }}>
      Öll verð eru án VSK. Krafa er send í netbanka og reikningar eru sendir í
      tölvupósti eða með rafrænni skeytamiðlun.
    </p>
  </>
);

const EnglishPricingDescription = (
  <>
    <p className="text-center" style={{ lineHeight: 2 }}>
      Free {trialPeriodInDays} day trial <br />
      No hidden fees <br />
      Cancel any time <br />
      60 day refunds <br />
    </p>

    <p style={{ marginTop: "2.5em" }}>
      We support payments in all major currencies. Above pricing does not
      include local VAT or sales tax.
    </p>
  </>
);

/** The pricing section displayed on the English version of the site */
const Pricing = props => {
  const currency = process?.env?.REACT_APP_CURRENCY || "EUR";
  const description = isIcelandicDomain
    ? IcelandicPricingDescription
    : EnglishPricingDescription;

  return (
    <section id="pricing" className="pricing">
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-12">
            <h2>{t("Pricing")}</h2>
            <hr className="colored" />
            <p></p>
          </div>
        </div>

        {/* ------------------- 3 tier pricing plan styling -----------------*/}
        {/* Commented out in case we switch to 3-tier pricing in the future. */}
        {/*<div className="row content-row">*/}
        {/*  /!* Pricing Table 1 *!/*/}
        {/*  <div className="col-sm-4 col-md-4">*/}
        {/*    <div className="pricing-item featured-first">*/}
        {/*      <div className="price">*/}
        {/*        <Price price={prices[currency][0]} currency={currency} />*/}
        {/*      </div>*/}
        {/*      <hr className="colored" />*/}
        {/*      <p>*/}
        {/*        {t("per month")} <br />*/}
        {/*        {t("1 - 3 employees")}*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  /!* Pricing Table 2 *!/*/}
        {/*  <div className="col-sm-4 col-md-4">*/}
        {/*    <div className="pricing-item featured">*/}
        {/*      <div className="price">*/}
        {/*        <Price price={prices[currency][1]} currency={currency} />*/}
        {/*      </div>*/}
        {/*      <hr className="colored" />*/}
        {/*      <p>*/}
        {/*        {t("per month")} <br />*/}
        {/*        {t("4 - 24 employees")}*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  /!* Pricing Table 3 *!/*/}
        {/*  <div className="col-sm-4 col-md-4">*/}
        {/*    <div className="pricing-item featured-last">*/}
        {/*      <div className="price">*/}
        {/*        <Price price={prices[currency][2]} currency={currency} />*/}
        {/*      </div>*/}
        {/*      <hr className="colored" />*/}
        {/*      <p>*/}
        {/*        {t("per month")} <br />*/}
        {/*        {t("25 - 50 employees")}*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/* ----------------------- 4 tier pricing --------------------------*/}
        <div className="row content-row show-grid">
          {/* Pricing Table 1 */}
          <Col sm={5} smOffset={1} md={4} mdOffset={2}>
            <div className="pricing-item">
              <div className="price">
                <Price price={prices[currency][0]} currency={currency} />
              </div>
              <p>
                {t("per month")} <br />
                {t("1 - 4 employees")}
              </p>
            </div>
          </Col>

          {/* Pricing Table 2 */}
          <Col sm={5} smOffset={0} md={4} mdOffset={0}>
            <div className="pricing-item">
              <div className="price">
                <Price price={prices[currency][1]} currency={currency} />
              </div>
              <p>
                {t("per month")} <br />
                {t("5 - 24 employees")}
              </p>
            </div>
          </Col>

          {/* Spacer */}
          <Col xs={12}>
            <div style={{ marginTop: "30px" }} />
          </Col>

          {/* Pricing Table 3 */}
          <Col sm={5} smOffset={1} md={4} mdOffset={2}>
            <div className="pricing-item">
              <div className="price">
                <Price price={prices[currency][2]} currency={currency} />
              </div>
              <p>
                {t("per month")} <br />
                {t("25 - 49 employees")}
              </p>
            </div>
          </Col>

          {/* Pricing Table 4 */}
          <Col sm={5} smOffset={0} md={4} mdOffset={0}>
            <div className="pricing-item">
              <div className="price">
                <Price price={prices[currency][3]} currency={currency} />
              </div>
              <p>
                {t("per month")} <br />
                {t("50 - 200 employees")}
              </p>
            </div>
          </Col>
        </div>

        <Row style={{ marginTop: "3.5em" }}>
          <Col
            xs={10}
            xsOffset={1}
            sm={10}
            smOffset={1}
            md={8}
            mdOffset={2}
            lg={8}
            className="text-center"
          >
            {description}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Pricing;
