import { combineReducers } from "redux";

import admin from "./adminReducer";
import companies from "./companiesReducer";
import employees from "./employeesReducer";
import error from "./errorReducer";
import locations from "./locationsReducer";
import transactions from "./transactionsReducer";
import projects from "./projectsReducer";
import user from "./userReducer";
import report from "./reportReducer";
import app from "./appReducer";

export default combineReducers({
  admin,
  companies,
  employees,
  error,
  locations,
  transactions,
  projects,
  user,
  report,
  app,
});
