import moment from "moment";
import momenttz from "moment-timezone";

import { trialPeriodInDays } from "./Constants";

/**
 * A timeless copyright notice. Returns "© 2017 - 2019 Tímavera ehf." if the
 * year is 2019.
 */
export function getCopyrightString() {
  const thisYear = moment().format(`YYYY`);
  return `© 2017 - ${thisYear} Tímavera ehf.`;
}

const hostname = window.location.hostname;
export const isTimaveraIs = hostname === "timavera.is";
export const isTimaveraCom = hostname === "timavera.com";
export const isTimaveraIe = hostname === "timavera.ie";
export const isTimaveraCoUk = hostname === "timavera.co.uk";
export const isStagingTimaveraIs = hostname === "staging.timavera.is";
export const isStagingTimaveraCom = hostname === "staging.timavera.com";
export const isIcelandicDomain = isTimaveraIs || isStagingTimaveraIs;

/**
 * Returns true if on a .is domain or if the timezone of the client is set
 * to Iceland.
 *
 * Used to determine if the Payday or Paddle dialog should be shown. In most
 * cases if the user is on timavera.is (or staging.timavera.is for testing)
 * the choice is clear that Payday should be displayed. The timezone check
 * is performed because we have a few Icelandic customers that use
 * timavera.com because they can not read Icelandic. So this function
 * ensures we offer Payday billing by default for those foreign speaking
 * Icelandic customers.
 *
 * @returns {boolean} true if on timavera.is or the timezone in the computer
 *   indicates the computer is in Iceland
 */
export const isIcelandic = () => {
  const tzGuess = momenttz.tz.guess(true).toLowerCase();
  const isIcelandTimezone =
    tzGuess === "atlantic/reykjavik" || tzGuess === "iceland";

  return isIcelandicDomain || isIcelandTimezone;
};

/** Returns true if the website is being run on the production environment */
export const isProduction = () =>
  isTimaveraIs || isTimaveraCom || isTimaveraIe || isTimaveraCoUk;

/** Returns true if the website is being run on the staging environment */
export const isStaging = () => isStagingTimaveraIs || isStagingTimaveraCom;

/** Returns true if the website is being run on localhost while developing */
export const isLocalhost = () => window.location.hostname === "localhost";

/**
 * Returns the most appropriate Tímavera email address for the site.
 *
 * The REACT_APP_CURRENCY environment variable is used to achieve correct
 * pre-rendering for SEO purposes. Hostname checking would be an alternative,
 * but it misses out on pre-rendering. Using the currency as an email address
 * determinant is perhaps not ideal but at the time of writing it fulfills the
 * requirement. A better alternative would be to add a new email address
 * environment variable, but adding it quite a bit of work. This will need
 * to be done if we add a new top level domain where the country uses EUR.
 *
 * @returns {string} Examples: "timavera@timavera.is", "timavera@timavera.com"
 */
export const getTimaveraEmail = () => {
  const currency = process?.env?.REACT_APP_CURRENCY || "USD";

  if (currency === "ISK") return "timavera@timavera.is";
  if (currency === "EUR") return "timavera@timavera.ie";
  if (currency === "USD") return "timavera@timavera.com";
  if (currency === "GBP") return "timavera@timavera.com"; // .co.uk not set up

  return "timavera@timavera.com"; // default fallback & local development
};

/**
 * Move the scroll position of the browser to the top of the page.
 * See: https://stackoverflow.com/a/1145012
 */
export function scrollToTopOfPage() {
  window.scrollTo(0, 0);
}

/** True if a string is not the empty string "", false otherwise */
const notEmpty = comment => typeof comment === "string" && comment !== "";

/** True if the list is empty or if it only contains empty string "" comments */
export const commentListIsEmpty = comments =>
  comments.length === 0 || !comments.some(({ comment }) => notEmpty(comment));

/** True if a time entry comment is a regular text comment */
export const isTextComment = comment =>
  notEmpty(comment) && !isImageComment(comment);

/** True if a time entry comment is an image comment */
export const isImageComment = comment =>
  notEmpty(comment) && comment.startsWith("https://timavera-images");

/**
 * Returns true if two string permission arrays are semantically the same. That
 * is, both contain the same unique elements. This extra guarding is because
 * the database value has no uniqueness constraint. It could therefore
 * theoretically happen that the needs value would be
 * ["manage_worklogs", "manage_worklogs"]. As far permissions go this is
 * exactly the same as ["manage_worklogs"]. The sorting is done to account for
 * other types of permissions in the future.
 *
 * Examples:
 *   +  true: samePermission(["2", "1"], ["1", "2"])
 *   +  true: samePermission(["1", "2"], ["1", "1", "2"])
 *   + false: samePermission(["1", "2"], ["1", "2", "3"])
 *
 *  "1", "2", "3" are used for brevity but in practice those would be strings
 *  like "manage_worklogs".
 *
 * @param needs1 {string[]} Example: ["manage_worklogs"]
 * @param needs2 {string[]} Example: ["manage_worklogs", "delete_worklogs"]
 * @returns {boolean} true if semantically the same, false otherwise
 */
export const samePermission = (needs1, needs2) => {
  const set1 = Array.from(new Set(needs1)).sort().toString();
  const set2 = Array.from(new Set(needs2)).sort().toString();

  return set1 === set2;
};

/**
 * Regex that matches "99.99%" of all valid email addresses. The regex is from
 * https://www.emailregex.com. The "// eslint-disable-line" comment at the end
 * silences ESLint: "Unnecessary escape character: \[  no-useless-escape". The
 * regex may be shorter, but it is preferable to just use the regular
 * expression as is from emailregex.com. If the warning is not silenced builds
 * will fail.
 */
const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

/**
 * True if given email string is in a valid email form, false otherwise
 *
 * @param email {string} Examples: "timavera@timavera.is" (true), "a@" (false)
 * @returns {boolean} true if valid email, false otherwise
 */
export const isValidEmail = email => emailRegex.test(email);

/**
 * True if given string is not a valid email, false otherwise.
 *
 * @param email {string} Example: "timavera@timavera.is" (false), "a@" (true)
 * @returns {boolean} true if invalid email, false otherwise
 */
export const isInvalidEmail = email => !emailRegex.test(email);

export const isSmallScreen = window.innerWidth <= 475;

/**
 * Returns true if a given ISO 8601 company created date time string is within
 * the first X days of what we consider to be the trial period. At the time of
 * writing 14 days.
 *
 * @param companyCreatedDateTime {string} ISO 8601 datetime string, for example
 *   "2024-12-18T11:48:59.567181+00:00".
 * @returns {boolean} true if the company was created less than 14 days ago,
 *   false otherwise.
 */
export const isWithinTrialPeriod = companyCreatedDateTime =>
  moment().diff(companyCreatedDateTime, "days") < trialPeriodInDays;

export const capitalizeFirstLetter = str =>
  !str || typeof str !== "string"
    ? ""
    : str.charAt(0).toUpperCase() + str.slice(1);
