import React from "react";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import { translateWithGlobals } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Project description": { is: "Verkefnalýsing" },
};

const t = key => translateWithGlobals(key, translations);

/**
 * A modal that currently only displays the project description as an
 * alternative to viewing it in a constrained multi-line input box.
 *
 * The component is named ProjectComments because it is intended to eventually
 * display project comments.
 */
function ProjectComments(props) {
  const { show, project, onCloseModal } = props;

  const closeModal = () => {
    onCloseModal();
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Project description")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{project?.description ?? ""}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button block={true} onClick={closeModal}>
          {t("Close window")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProjectComments;
