import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Alert,
  Button,
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
  Radio,
  Row,
  Col,
} from "react-bootstrap";

import API from "../../api";
import { translate } from "../../utils/Translations";
import { isIcelandic } from "../../utils/HelperFunctions";
import {
  createPaydayBilling,
  hideBillingWindowWhileOnTrial,
  validatePaddleBilling,
} from "../../actions";
import { TextLoadingSpinner } from "../../components/LoadingSpinner";
import {
  getTimaveraEmail,
  isIcelandicDomain,
  isInvalidEmail,
  isProduction,
  isTimaveraCoUk,
  isTimaveraIe,
} from "../../utils/HelperFunctions";
import BillingPaddleNotice from "../../components/modals/BillingPaddleNotice";
import { RollbarError } from "../../utils/Rollbar";

// prettier-ignore
const translations = {
  // ----------------------------- Shared --------------------------------------
  "Close": { is: "Loka" },
  "Subscription": { is: "Áskrift" },
  "Confirm subscription later": {
    is: "Staðfesta áskrift seinna"
  },
  "Trial period over": { is: "Prufu tímabili lokið" },
  "Subscription plan": { is: "Starfsmanna fjöldi fyrirtækis" },
  "unlimited number of employees": { is: "ótakmarkaður fjöldi starfsmanna" },
  "free": {
    en: ", free 🎉",
    is: ", gjaldfrjálst 🎉",
  },
  "4 - 24 employees": { is: "4 - 24 starfsmenn" },
  "25 - 50 employees": { is: "25 - 50 starfsmenn" },

  "plan1": { is: "1 - 4 starfsmenn", en: "1 - 4 employees" },
  "plan2": { is: "5 - 24 starfsmenn", en: "5 - 24 employees" },
  "plan3": { is: "25 - 49 starfsmenn", en: "25 - 49 employees" },
  "plan4": { is: "50 - 200 starfsmenn", en: "50 - 200 employees"},
  "Billing complete header": {
    is: "Velkomin í viðskipti! 🎉",
    en: "Welcome to Tímavera! 🎉",
  },

  // ----------------------------- Payday --------------------------------------
  "SSN of company": { is: "Kennitala fyrirtækis" },
  "Billing email": { is: "Netfang sem fær PDF af reikningum" },
  "Receive monthly invoice": { is: "Greiða með kröfu í heimabanka"},
  "Please use only digits": { is: "Vinsamlegast notið einungis tölur" },
  "Pay with a card": { is: "Greiða með korti" },
  "Payday-explanation-text": {
    en: "Please provide the following billing information to continue using " +
      "Tímavera. Every month a PDF invoice is emailed and a claim " +
      "(ísl: krafa) is raised to the bank account of the company/payer. " +
      "Prices are without VAT.",
    is: "Vinsamlegast gefið upp eftirfarandi upplýsingar til að halda " +
      "áfram viðskiptum. Mánaðarlega sendast kröfur í heimabanka og PDF af " +
      "reikningum í tölvupósti. Verð eru án VSK.",
  },
  "Payday-explanation-text-should-subscribe": {
    en: "Please provide the following billing information to continue using " +
      "Tímavera.",
    is: "Vinsamlegast gefið upp eftirfarandi upplýsingar til að halda áfram " +
      "viðskiptum.",
  },
  "Payday-explanation-text-early-subscribe": {
    en: "Please provide the following billing information to confirm a " +
      "subscription.",
    is: "Vinsamlegast gefið upp eftirfarandi upplýsingar til að staðfesta " +
      "áskrift.",
  },
  "Payday-explanation-text-logistics": {
    en: "Every month a PDF invoice is emailed and a claim (ísl: krafa) is " +
      "raised to the bank account of the company/payer. Prices are without " +
      "VAT.",
    is: "Mánaðarlega sendast kröfur í heimabanka og PDF af reikningum í " +
      "tölvupósti. Verð eru án VSK.",
  },
  "Payday-billing-complete": {
    en: `Please send us an email at ${getTimaveraEmail()} if you would like ` +
      "future invoices to be sent electronically via XML (ísl: rafræn " +
      "skeytamiðlun) directly to your bookkeeping system.",
    is: `Vinsamlegast sendið okkur tölvupóst á ${getTimaveraEmail()} ef ` +
      "reikningar ættu að sendast með rafrænni skeytamiðlun (XML) beint í " +
      "bókhaldskerfi fyrirtækis.",
  },

  // ----------------------------- Paddle --------------------------------------
  "Pay with credit card": { is: "Greiða með korti" },
  "Pay with a bank debit request": { is: "Greiða með kröfu í heimabanka" },
  "Paddle-explanation-text-should-subscribe": {
    en: "Please provide payment details to continue using Tímavera. Prices " +
      "do not include VAT or sales tax.",
    is: "Vinsamlegast staðfestið greiðslu upplýsingar til að halda áfram" +
      " notkun á Tímaveru. Verð eru án VSK.",
  },
  "Paddle-explanation-text-early-subscribe": {
    en: "Please provide payment details to confirm a Tímavera subscription. " +
      "Prices do not include VAT or sales tax.",
    is: "Vinsamlegast gefið upp greiðslu upplýsingar til að staðfesta " +
      "áskrift að Tímaveru. Verð eru án VSK.",
  },
  "Paddle-waiting-for-verification": {
    en: "Waiting for verification, could take up to a minute.",
    is: "Bíð eftir staðfestingu, gæti tekið allt að mínútu.",
  },

  "Paddle-billing-complete": {
    en: "Your monthly subscription has been verified and your credit card " +
      "will be billed monthly. The subscription can be cancelled at any " +
      `time in Settings or by sending us an email at ${getTimaveraEmail()}.`,
    is: "Áskriftin þín hefur verið staðfest og kredit kortið verður rukkað " +
      "mánaðarlega. Áskriftinni er hægt að segja upp hvenær sem er í " +
      `Stillingum eða með því að senda okkur tölvupóst á ${getTimaveraEmail()}.`,
  },

  "Unable to verify subscription": { is: "Gat ekki sannreint áskrift"},
  "Paddle-billing-failed": {
    en: "Your subscription might have gone through but we were unable to " +
      "verify it on our end. Do not worry, you can still use the product " +
      "as you please and we will contact you as soon as possible if needed. " +
      "Most of the time this is resolved automatically with time.",
    is: "Áskriftin var mögulega staðfest en við gátum ekki skorið úr um það " +
      "á okkar enda. Engar áhyggjur, þú getur notað Tímaveru eins og þér " +
      "hentar og við munum hafa samband eins fljótt og auðið er ef þörf er " +
      "á. Oftast leysist þetta sjálfkrafa með tímanum."
  },
};

const t = key => translate(key, translations);

const prices = {
  plan1: { ISK: 8, OTH: 50 }, // 1-4 employees, 2023 pricing
  plan2: { ISK: 14, OTH: 100 }, // 5-24 employees, 2023 pricing
  plan3: { ISK: 30, OTH: 200 }, // 25-49 employees, 2023 pricing
  plan4: { ISK: 60, OTH: 400 }, // 50-200 employees, 2023 pricing

  // Legacy prices
  plan2020_1: { ISK: 5, OTH: 25 }, // 1-3 employees, 2020 pricing
  plan2020_2: { ISK: 10, OTH: 50 }, // 4-24 employees, 2020 pricing
  plan2020_3: { ISK: 25, OTH: 150 }, // 25-50 employees, 2020 pricing
};

/**
 * Returns a localized and human-readable string for a given billing string.
 *
 * Legacy plans that are no longer in use and very unlikely to be used again:
 *   + "Legacy5000"
 *   + "Legacy7000"
 *   + "FreeTier100Hours"
 *   + "ThreeEmployeesMax2020"
 *
 * @param billingPlan {string} Examples: "FourEmployeesMax2023",
 *   "FortyNineEmployeesMax2023", "FreeTierUnlimited".
 * @returns {string} E.g.: "1 - 4 employees, €50", "5 - 24 starfsmenn, 14þkr"
 */
export const getSubscriptionLabel = billingPlan => {
  switch (billingPlan) {
    case "FreeTierUnlimited":
      return t("unlimited number of employees") + t("free");
    case "Legacy8000":
      return t("unlimited number of employees") + getPrice("plan1");
    case "TwentyFourEmployeesMax2020":
      return t("4 - 24 employees") + getPrice("plan2020_2");
    case "TwentyFiveOrMoreEmployees2020":
      return t("25 - 50 employees") + getPrice("plan2020_3");
    case "FourEmployeesMax2023":
      return t("plan1") + getPrice("plan1");
    case "TwentyFourEmployeesMax2023":
      return t("plan2") + getPrice("plan2");
    case "FortyNineEmployeesMax2023":
      return t("plan3") + getPrice("plan3");
    case "TwoHundredEmployeesMax2023":
      return t("plan4") + getPrice("plan4");
    default:
      RollbarError(`Unhandled billing plan "${billingPlan}"`);
      return "";
  }
};

/**
 * Returns a price string that is added to the end of how many employees
 * each subscription plan has.
 *
 * @param plan {string} "plan1", "plan2", etc
 * @param prefersCardPayment {boolean} true if an Icelandic company has
 *   indicated they prefer paying with a card, false otherwise
 * @returns {string} Examples: ", 14þkr", ", €50/m", etc
 */
const getPrice = (plan, prefersCardPayment = false) => {
  if (prefersCardPayment && isIcelandic()) {
    return `, €${prices[plan]["OTH"]}/m`;
  } else if (isIcelandic()) {
    return `, ${prices[plan]["ISK"]}þkr`;
  } else {
    let currencySymbol = "$";
    if (isTimaveraIe) currencySymbol = "€";
    if (isTimaveraCoUk) currencySymbol = "£";

    return `, ${currencySymbol}${prices[plan]["OTH"]}/m`;
  }
};

/**
 * A helper responsive Row/Col that provides the maximum width we want to
 * provide in the billing alert box.
 */
const RowCol = ({ children }) => (
  <Row style={{ marginTop: "2em" }}>
    <Col xs={12} sm={8} smOffset={2} md={6} mdOffset={3} lg={6} lgOffset={3}>
      {children}
    </Col>
  </Row>
);

/** A responsive Row/Col for billing form inputs */
const FormInputRowCol = ({ children }) => (
  <Row>
    <Col
      xs={12}
      sm={6}
      smOffset={3}
      md={4}
      mdOffset={4}
      lg={4}
      lgOffset={4}
      style={{ textAlign: "center", marginTop: "1em" }}
    >
      {children}
    </Col>
  </Row>
);

function SelectSubscriptionPlan(props) {
  const { subscriptionPlan, setSubscriptionPlan, prefersCardPayment } = props;

  return (
    <FormGroup controlId="formSubscriptionPlanControl" className="text-center">
      <ControlLabel>{t("Subscription plan")}</ControlLabel>
      <Radio
        name="radioSubscription5"
        onChange={() => setSubscriptionPlan(1)}
        checked={subscriptionPlan === 1}
      >
        {t("plan1") + getPrice("plan1", prefersCardPayment)}
      </Radio>
      <Radio
        name="radioSubscription6"
        onChange={() => setSubscriptionPlan(2)}
        checked={subscriptionPlan === 2}
      >
        {t("plan2") + getPrice("plan2", prefersCardPayment)}
      </Radio>
      <Radio
        name="radioSubscription7"
        onChange={() => setSubscriptionPlan(3)}
        checked={subscriptionPlan === 3}
      >
        {t("plan3") + getPrice("plan3", prefersCardPayment)}
      </Radio>
      <Radio
        name="radioSubscription8"
        onChange={() => setSubscriptionPlan(4)}
        checked={subscriptionPlan === 4}
      >
        {t("plan4") + getPrice("plan4", prefersCardPayment)}
      </Radio>
    </FormGroup>
  );
}

function PaydayBilling(props) {
  const { onSubmit, prefersCardPayment, switchToPaddle } = props;

  const [ssn, setSsn] = useState("");
  const [ssnErrorMessage, setSsnErrorMessage] = useState("");
  const [emailBilling, setEmailBilling] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState(1);

  const showBillingWhileOnTrial = useSelector(
    state => state.app.showBillingWhileOnTrial
  );

  const submitDisabled = ssn.length !== 10 || isInvalidEmail(emailBilling);

  const handleSsnChange = e => {
    // Replace spaces with empty string
    const value = (e.target.value || "").replace(/\s/g, "");
    if (isNaN(value) && value.length < 11) {
      // We only want numbers
      setSsnErrorMessage(t("Please use only digits"));
    } else {
      setSsnErrorMessage("");
      if (value.length < 11) {
        // SSNs are only 10 integers
        setSsn(value);
      }
    }
  };

  const handleEmailBillingChange = e => setEmailBilling(e.target.value);

  const submit = e => {
    e.preventDefault();
    onSubmit(ssn, emailBilling, subscriptionPlan);
  };

  return (
    <>
      <RowCol>
        <p className="text-center">
          {showBillingWhileOnTrial
            ? t("Payday-explanation-text-early-subscribe")
            : t("Payday-explanation-text-should-subscribe")}{" "}
          {t("Payday-explanation-text-logistics")}
        </p>
      </RowCol>

      <Form onSubmit={submit}>
        <FormInputRowCol>
          <SelectSubscriptionPlan
            subscriptionPlan={subscriptionPlan}
            setSubscriptionPlan={setSubscriptionPlan}
            prefersCardPayment={prefersCardPayment}
          />

          <div style={{ marginTop: "2em" }} />

          {ssnErrorMessage && (
            <p className="text-center" style={{ color: "red" }}>
              {ssnErrorMessage}
            </p>
          )}

          <FormGroup controlId="formSsnControl">
            <FormControl
              type="text"
              onChange={handleSsnChange}
              value={ssn}
              placeholder={t("SSN of company")}
            />
          </FormGroup>
          <FormGroup controlId="formEmailBillingControl">
            <FormControl
              type="text"
              onChange={handleEmailBillingChange}
              value={emailBilling}
              placeholder={t("Billing email")}
            />
          </FormGroup>
        </FormInputRowCol>

        <RowCol>
          <Button
            disabled={submitDisabled}
            type="submit"
            bsStyle="success"
            block={true}
            onClick={submit}
          >
            {t("Receive monthly invoice")}
          </Button>

          <Button bsStyle="link" block={true} onClick={switchToPaddle}>
            {t("Pay with a card")}
          </Button>
        </RowCol>
      </Form>
    </>
  );
}

/**
 * The second paragraph that is displayed in the Paddle billing dialog.
 *
 * A reminder for VAT registered companies that subscribe with Paddle to add
 * their VAT details to avoid paying extra VAT that they do not need to pay.
 * Currently, it shows a more explicit message when the site is timavera.ie.
 * That is because we know for sure that companies in Ireland can have their
 * VAT zeroed out. For the rest of the countries we make a less assertive
 * statement but still remind them.
 */
function PaddleExplanationText2(props) {
  // At the time of writing Paddle does not correctly handle VAT registered
  // companies in Iceland despite correctly filling the information out.
  // With Icelandic VAT information added invoices should not contain any
  // VAT but yet they do. EEA intra community revers VAT rules. Have
  // contacted Paddle to have this corrected but no luck. Reason is unknown.
  // Maybe Iceland is too small of a market. Maybe there is no good way to
  // verify Icelandic VAT numbers. Until then there is no reason to remind
  // Icelandic companies to fill out VAT information because it does nothing.
  if (isIcelandic()) return null;

  if (isTimaveraIe) {
    return (
      <p className="text-center" style={{ marginBottom: "2em " }}>
        If the payer is a registered VAT company press "<i>+ Add VAT</i>" in the{" "}
        <i>Payment</i> step to skip paying VAT.
      </p>
    );
  }

  return (
    <p className="text-center" style={{ marginBottom: "2em " }}>
      Remember to press "<i>+ Add VAT</i>" in the <i>Payment</i> step if
      applicable.
    </p>
  );
}

/**
 * A link styled button to switch to Payday billing if the customer is
 * Icelandic. For international companies this button is not shown because
 * they can not receive nor pay debit requests unless they have an Icelandic
 * social security number. The button offers Icelandic users a way back to
 * the Payday billing dialog if they were curious and clicked "Pay with card".
 */
function SwitchToPaydayIfIcelandic({ switchToPayday }) {
  if (!isIcelandic()) return null;

  return (
    <Button bsStyle="link" block={true} onClick={switchToPayday}>
      {t("Pay with a bank debit request")}
    </Button>
  );
}

function PaddleBilling(props) {
  const { company, onSubmit, prefersCardPayment, switchToPayday } = props;
  const [paddleScriptLoaded, setPaddleScriptLoaded] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(1);
  const showBillingWhileOnTrial = useSelector(
    state => state.app.showBillingWhileOnTrial
  );

  useEffect(() => {
    // Rather than always loading the Paddle script in order to be able to use
    // Paddle checkout we opt to just load it on demand, that is when this
    // component is mounted.

    // This logic could actually be moved to a re-usable hook in the future but
    // since this is the only external script that we are using (at the time of
    // writing this) we can refactor that later.
    const script = document.createElement("script");
    script.src = "https://cdn.paddle.com/paddle/paddle.js";
    script.async = true;
    script.onload = () => {
      const paddleVendor = isProduction() ? 113366 : 3043; // TODO pull into helper?
      window.Paddle.Setup({
        vendor: paddleVendor,
        eventCallback: data => {
          // The data.event will specify the event type. For all event types see
          // https://developer.paddle.com/reference/84fbd65638c3f-checkout-events#paddlejs-event-callback
          if (data.event === "Checkout.Complete") {
            onSubmit();
          }
        },
      });
      if (!isProduction()) {
        window.Paddle.Environment.set("sandbox");
      }
      setPaddleScriptLoaded(true);
      return true; // XCXC NEEDED?
    };
    document.body.appendChild(script);
    return () => {
      // Let us remove the paddle script when unmounting this component
      document.body.removeChild(script);
    };
  }, [onSubmit]);

  if (!paddleScriptLoaded) return <TextLoadingSpinner />;

  const subscriptionToProductMap = {
    1: isProduction() ? 830108 : 51494,
    2: isProduction() ? 830109 : 51495,
    3: isProduction() ? 830110 : 51496,
    4: isProduction() ? 830111 : 51497,
  };

  const submit = e => {
    e.preventDefault();
    const product = subscriptionToProductMap[subscriptionPlan];

    // All available checkout parameters
    // https://developer.paddle.com/reference/5e0171ec215eb-checkout-parameters
    window.Paddle.Checkout.open({
      product: product,
      passthrough: company.id,
      method: "overlay",
    });
  };

  return (
    <Form onSubmit={submit}>
      <RowCol>
        <p className="text-center">
          {showBillingWhileOnTrial
            ? t("Paddle-explanation-text-early-subscribe")
            : t("Paddle-explanation-text-should-subscribe")}
        </p>
      </RowCol>

      <FormInputRowCol>
        <SelectSubscriptionPlan
          subscriptionPlan={subscriptionPlan}
          setSubscriptionPlan={setSubscriptionPlan}
          prefersCardPayment={prefersCardPayment}
        />
      </FormInputRowCol>

      <RowCol>
        <PaddleExplanationText2 />

        <Button type="submit" bsStyle="success" block={true} onClick={submit}>
          {t("Pay with credit card")}
        </Button>

        <SwitchToPaydayIfIcelandic switchToPayday={switchToPayday} />
      </RowCol>
    </Form>
  );
}

function PaymentOptions(props) {
  const { company, submitPayday, submitPaddle } = props;

  const [showModal, setShowModal] = useState(false);
  const [prefersCardPayment, setPrefersCardPayment] = useState(false);

  // Default. For non-Icelandic companies we only offer card payments for now.
  let paymentOption = (
    <PaddleBilling company={company} onSubmit={submitPaddle} />
  );

  // The default for Icelandic customers is to pay via a debit request
  // ("krafa") in the Icelandic banking system ("heimabanki"). Icelandic
  // customers can pay with a card but ISK is not supported by Paddle.
  if (isIcelandic()) {
    paymentOption = (
      <PaydayBilling
        onSubmit={submitPayday}
        prefersCardPayment={prefersCardPayment}
        switchToPaddle={() => setShowModal(true)}
      />
    );
  }

  // For Icelandic customers that prefer paying with a card
  if (prefersCardPayment) {
    paymentOption = (
      <PaddleBilling
        company={company}
        onSubmit={submitPaddle}
        prefersCardPayment={prefersCardPayment}
        switchToPayday={() => setPrefersCardPayment(false)}
      />
    );
  }

  return (
    <>
      {paymentOption}
      <BillingPaddleNotice
        show={showModal}
        onCancel={() => setShowModal(false)}
        onConfirm={() => {
          setShowModal(false);
          setPrefersCardPayment(true);
        }}
      />
    </>
  );
}

function VerificationMessage(props) {
  const { setShowConfirmation } = props;

  const message = !isIcelandicDomain
    ? t("Paddle-billing-complete")
    : t("Payday-billing-complete");

  return (
    <Alert>
      <h3 className="text-center">{t("Billing complete header")}</h3>

      <RowCol>
        <p className="text-center" style={{ marginBottom: "2em" }}>
          {message}
        </p>

        <Button onClick={e => setShowConfirmation(false)} block={true}>
          {t("Close")}
        </Button>
      </RowCol>
    </Alert>
  );
}

function FailedPaddleVerificationMessage(props) {
  const { setShowConfirmation } = props;

  return (
    <Alert>
      <h3 className="text-center">{t("Unable to verify subscription")}</h3>
      <RowCol>
        <p className="text-center" style={{ marginBottom: "2em" }}>
          {t("Paddle-billing-failed")}
        </p>

        <Button block={true} onClick={e => setShowConfirmation(false)}>
          {t("Close")}
        </Button>
      </RowCol>
    </Alert>
  );
}

/**
 * A button to close/hide the billing window. Only shown to prospects on a trial
 * that clicked a button show the billing window to subscribe early. Gives them
 * an option (other than a page refresh) to hide the billing window if they
 * change their mind.
 *
 * @returns {React.JSX.Element|null}
 */
function ButtonToHideBillingIfShowingToTrials() {
  const dispatch = useDispatch();
  const showBillingWhileOnTrial = useSelector(
    state => state.app.showBillingWhileOnTrial
  );

  if (!showBillingWhileOnTrial) return null;

  return (
    <RowCol>
      <Button block onClick={() => dispatch(hideBillingWindowWhileOnTrial())}>
        {t("Confirm subscription later")}
      </Button>
    </RowCol>
  );
}

export default function Billing(props) {
  const { company } = props;
  const dispatch = useDispatch();
  const showBillingWhileOnTrial = useSelector(
    state => state.app.showBillingWhileOnTrial
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [failedValidation, setFailedValidation] = useState(false);
  const [displayBilling, setDisplayBilling] = useState(false);

  let headingTitle = t("Trial period over");
  const isOlderThanNinetyDays = moment().diff(company.created, "days") > 90;
  const notPayingCustomer = !company.paying_customer;
  // "&& notPayingCustomer" ensures billing is not shown to new customers
  const showBillingOnTrial = showBillingWhileOnTrial && notPayingCustomer;
  const shouldDisplayBilling = displayBilling || showBillingOnTrial;

  if (showConfirmation) {
    if (failedValidation) {
      return (
        <FailedPaddleVerificationMessage
          setShowConfirmation={setShowConfirmation}
        />
      );
    }

    if (notPayingCustomer) {
      return (
        <Alert>
          <h3 className="text-center">
            {t("Paddle-waiting-for-verification")}
          </h3>
          <TextLoadingSpinner />
        </Alert>
      );
    }

    return <VerificationMessage setShowConfirmation={setShowConfirmation} />;
  }

  // The company has submitted payments, but we were unable to verify it on our
  // end. We need to follow up on our end before we ask them to submit new
  // payment information.
  if (failedValidation) return null;

  // API determines if billing should show or not, see `/api/billing/status`.
  API.checkBillingStatus().then(
    success => {
      if (success.should_become_customer) setDisplayBilling(true);
    },
    error => {}
  );

  // Hide billing if the API says so unless explicitly requested to be shown
  // by a prospect on a trial that may want to confirm a subscription early.
  if (!shouldDisplayBilling) return null;

  // Situations where "Subscription" as a heading is more appropriate instead
  // of "Trial over". For example older accounts resuming a subscription (e.g.
  // seasonal companies) or trial accounts that want to subscribe right away.
  if (isOlderThanNinetyDays || showBillingWhileOnTrial) {
    headingTitle = t("Subscription");
  }

  const submitPayday = (ssn, emailBilling, subscriptionPlan) => {
    setShowConfirmation(true);
    const data = {
      ssn: ssn,
      email_billing: emailBilling,
      subscription_plan: subscriptionPlan,
    };

    dispatch(createPaydayBilling(data)).then(resp => {
      if (resp.type === "CREATE_PAYDAY_BILLING_REJECTED")
        setShowConfirmation(false);
    });
  };

  /**
   * The company subscribes via paddle checkout. Upon a successful checkout two
   * things happen in parallel:
   *
   * 1. Paddle sends a request to our server via webhook, which will update the
   *    company to a paying customer.
   * 2. validatePaddle function gets called as a callback from the checkout.
   *    validatePaddle is checking if the company is marked as a paying
   *    customer in our system yet.
   *
   * However, Paddle's request is queued up at Paddle's backend. Meaning that
   * there could be a delay between the checkout succeeding and their request
   * hitting our server. That plus network delays could result in a race
   * condition where validatePaddle hits our server before Paddle's request. To
   * mitigate this, we have validatePaddle check with our server if the company
   * is a paying customer every 5 seconds for about a minute.
   */
  const validatePaddle = retryAttempt => {
    setShowConfirmation(true);

    if (retryAttempt > 10) {
      setFailedValidation(true);
      return;
    }

    dispatch(validatePaddleBilling()).then(resp => {
      if (
        resp.type === "VALIDATE_PADDLE_BILLING_REJECTED" ||
        !resp.payload.paying_customer
      ) {
        setTimeout(() => {
          validatePaddle((retryAttempt += 1));
        }, 5000);
      }
    });
  };

  return (
    <Alert>
      <h3 className="text-center">{headingTitle}</h3>

      <PaymentOptions
        company={company}
        submitPayday={submitPayday}
        submitPaddle={() => validatePaddle(1)}
      />

      <ButtonToHideBillingIfShowingToTrials />
    </Alert>
  );
}
