/**
 * The string value that signals the employee has permission to manually add
 * and edit worklogs. Used in the `needs` column in the employee table.
 *
 * @type {string}
 */
export const manageWorklogs = "manage_worklogs";

/**
 * The string value that signals the employee has permission to manually create
 * projects. Used in the `needs` column in the employee table.
 *
 * @type {string}
 */
export const createProjects = "create_projects";

/**
 * When a new employee is created these are the permissions or needs that
 * should apply.
 *
 * @type {string[]}
 */
export const defaultEmployeePermissions = [];

/** URL to the Tímavera app in the Apple App Store */
export const aasUrl =
  "https://itunes.apple.com/us/app/klukkinn/id1309092162?mt=8";

/** URL to the Tímavera app in the Google Play Store */
export const gpsUrl =
  "https://play.google.com/store/apps/details?id=is.klukkinn.app";

/** Apple App Store app ID, used in smart app banner on iOS Safari */
export const aasAppID = "1309092162";

/** How many days a standard trial lasts for */
export const trialPeriodInDays = 14;
