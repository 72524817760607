import React from "react";
import { Button, Modal } from "react-bootstrap";

import { translate } from "../../utils/Translations";

// prettier-ignore
const translations = {
  "Pay by card": { is: "Greiða með korti" },
  "Cancel, pay via bank": { is: "Hætta við, greiða með kröfu" },

  "paragraph1": {
    en: "Icelandic customers can pay with a card but ISK is unfortunately " +
      "not supported by our reseller Paddle which handles secure " +
      "card payments. Invoices are paid in euros with Icelandic VAT added.",
    is: "Íslenskum viðskiptavinum býðst að greiða með korti en því miður " +
      "eru íslenskar krónur ekki í boði hjá endursöluaðila okkar Paddle sem " +
      "sér um öruggar kortafærslur. Reikningar greiðast í evrum ásamt " +
      "íslenskum virðisaukaskatti.",
  },

  "paragraph2": {
    en: "If an Icelandic customer confirms a subscription with a card " +
      "payment it is no problem to switch later to Icelandic bank debit " +
      "requests (krafa) in ISK. Please get in touch.",
    is: "Ef áskrift er staðfest með kortagreiðslum þá er lítið mál að " +
      "skipta seinna yfir í netbanka kröfur í íslenskum krónum.",
  },
};

const t = key => translate(key, translations);

/**
 * A modal that alerts prospective Icelandic users that are about to confirm
 * a subscription that they can not pay with ISK when paying with a card.
 *
 * @param show {boolean}
 * @param onCancel {function}
 * @param onConfirm {function}
 */
function BillingPaddleNotice({ show, onCancel, onConfirm }) {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Pay by card")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{t("paragraph1")}</p>
        <p>{t("paragraph2")}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="submit"
          bsStyle="success"
          block={true}
          onClick={onConfirm}
        >
          {t("Pay by card")}
        </Button>
        <Button onClick={onCancel} block={true}>
          {t("Cancel, pay via bank")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BillingPaddleNotice;
