const initialState = {
  showBillingWhileOnTrial: false,
};

/**
 * The app reducer manages global application state and settings that are not
 * tied to specific API resources or domain objects. This includes UI state
 * (modals, navigation, themes) and application-wide settings.
 *
 * Most application state should ideally live in component state or parent
 * component state and be piped via props. This reducer is here for the rare
 * cases where it would be convoluted to pass down props.
 *
 * This is distinct from the user reducer which handles API responses and
 * authentication state, or other reducers that manage domain-specific data.
 */
export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case "SHOW_BILLING_WINDOW": {
      return {
        ...state,
        showBillingWhileOnTrial: true,
      };
    }

    case "HIDE_BILLING_WINDOW": {
      return {
        ...state,
        showBillingWhileOnTrial: false,
      };
    }

    default: {
      return state;
    }
  }
}
