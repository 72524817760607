import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Well } from "react-bootstrap";
import {
  changePassword,
  switchToTimeClock,
  showBillingWindowWhileOnTrial,
} from "../actions";
import { CenteredLoadingSpinner } from "./LoadingSpinner";
import {
  ChangePassword,
  CancelSubscription,
  SwitchToTimeClock,
} from "./modals";
import { translate } from "../utils/Translations";
import moment from "moment";
import { trialPeriodInDays } from "../utils/Constants";
import { getSubscriptionLabel } from "../pages/dashboard/Billing";

// prettier-ignore
const translations = {
  "Account": { is: "Aðgangur" },
  "Name": { is: "Nafn" },
  "Email": { is: "Tölvupóstur" },
  "Email us to update.": { is: "Sendið okkur póst til að breyta."},

  "Billing": { is: "Áskrift" },
  "Subscription plan: ": { is: "Áskriftar plan: " },
  "Confirm subscription": { is: "Staðfesta áskrift" },
  "Cancel subscription": { is: "Hætta í áskrift" },
  "Trial": { is: "Prufa" },
  "days remaining": { is: "dagar eftir" },
  "1 day remaining": { is: "1 dagur eftir"},
  "less than 1 day remaining": { is: "minna en 1 dagur eftir" },
  "day trial ended at": { is: "daga prufu lauk" },

  "Security": { is: "Öryggi" },
  "Change password": { is: "Breyta lykilorði" },
  "Change company account password.": {
    is: "Breyta lykilorði fyrirtækja aðgangs."
  },

  "Switch to time clock": { is: "Skipta yfir í stimpilklukku"},
  " account": { is: " aðgang" },
  "Time clock website": { is: "Vefsíðu stimpilklukka" },
  "time_clock_paragraph": {
    en: "For employees to clock in & out in a shared office computer or " +
      "tablet.",
    is: "Fyrir starfsmenn til að stimpla sig inn og út í sameiginlegri tölvu " +
      "eða spjaldtölvu.",
  }
};

const t = key => translate(key, translations);

/**
 * Returns a localized and human-readable string for the remaining company
 * trial period.
 *
 * Example values:
 *   "Trial: 14 days remaining."
 *   "Trial: 2 days remaining."
 *   "Trial: 1 day remaining."
 *   "Trial: less than 1 day remaining."
 *   "14 day trial ended at 18 Dec 2024."
 *
 * @param companyCreated {string} ISO 8601 datetime string
 * @returns {string}
 */
const getRemainingTrialTime = companyCreated => {
  const trialEnds = moment(companyCreated).add(trialPeriodInDays, "days");

  // Plus 1 because moment diff returns a truncated int number. Ensures new
  // trial companies see 14 days instead of 13 and potentially feeling shafted.
  const daysRemaining = trialEnds.diff(moment(), "days") + 1;

  if (daysRemaining < 0) {
    const endDate = trialEnds.format("D MMM YYYY");
    return `${trialPeriodInDays} ${t("day trial ended at")} ${endDate}.`;
  }

  let remain = `${daysRemaining} ${t("days remaining")}`;
  if (daysRemaining === 1) remain = t("1 day remaining");
  if (daysRemaining <= 1) remain = t("less than 1 day remaining");

  return `${t("Trial")}: ${remain}.`;
};

const BillingSection = () => {
  const [showCancelSubscription, setShowCancelSubscription] = useState(false);
  const company = useSelector(state => state.user.data);
  const dispatch = useDispatch();

  if (!company.paying_customer) {
    return (
      <>
        <Button
          block
          bsStyle="success"
          onClick={() => dispatch(showBillingWindowWhileOnTrial())}
        >
          {t("Confirm subscription")}
        </Button>

        <p style={{ marginTop: "1em" }}>
          {getRemainingTrialTime(company.created)}
        </p>
      </>
    );
  }

  return (
    <>
      <Button block onClick={() => setShowCancelSubscription(true)}>
        {t("Cancel subscription")}
      </Button>

      <p style={{ marginTop: "1em" }}>
        {t("Subscription plan: ") + getSubscriptionLabel(company.billing_plan)}.
      </p>

      {/* Modal */}
      <CancelSubscription
        show={showCancelSubscription}
        onCloseModal={() => setShowCancelSubscription(false)}
      />
    </>
  );
};

const Settings = props => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showTimeClock, setShowTimeClock] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  if (user.fetching) {
    return <CenteredLoadingSpinner />;
  }

  return (
    <div className="text-center">
      <Row>
        <Col md={6}>
          <Well>
            <h3>{t("Account")}</h3>
            <p>
              <strong>{t("Name")}:</strong> {user.data.name}
              <br />
              <strong>{t("Email")}:</strong> {user.data.email}
            </p>
            <p>{t("Email us to update.")}</p>
          </Well>
        </Col>

        <Col md={6}>
          <Well>
            <h3>{t("Billing")}</h3>
            <BillingSection />
          </Well>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Well>
            <h3>{t("Security")}</h3>
            <Button block onClick={() => setShowChangePassword(true)}>
              {t("Change password")}
            </Button>

            <p style={{ marginTop: "1em", marginBottom: "2.055em" }}>
              {t("Change company account password.")}
            </p>
          </Well>
        </Col>

        <Col md={6}>
          <Well>
            <h3>{t("Time clock website")}</h3>

            <Button block onClick={() => setShowTimeClock(true)}>
              {t("Switch to time clock")}
              <span className="hidden-xs">{t(" account")}</span>
            </Button>

            <p style={{ marginTop: "1em" }}>{t("time_clock_paragraph")}</p>
          </Well>
        </Col>
      </Row>

      {/* Modals */}
      <ChangePassword
        show={showChangePassword}
        onCloseModal={() => setShowChangePassword(false)}
        onSubmit={data => dispatch(changePassword(data))}
      />
      <SwitchToTimeClock
        show={showTimeClock}
        onCloseModal={() => setShowTimeClock(false)}
        onSubmit={data => dispatch(switchToTimeClock(data))}
      />
    </div>
  );
};

export default Settings;
